import { Injectable } from '@angular/core'
import { AREACONFIG, availableAreas } from '../app/constants/areas.constants'
import { EnvironmentService } from './environment.service'

@Injectable({
	providedIn: 'root',
})
export class AreasService {
	constructor(private env: EnvironmentService) {}

	getAvailableAreas(): AREACONFIG[] {
		return this.recursiveFilter(availableAreas)
		// return AREAS;
	}

	recursiveFilter(areas: AREACONFIG[]) {
		var matches: AREACONFIG[] = []
		if (!areas) return matches

		areas.forEach((area) => {
			if (this.filterBool(area)) {
				matches.push(area)
				let childResults = this.recursiveFilter(area.subAreas!)
				if (childResults.length > 0) {
					area.subAreas = childResults
				} else {
					area.subAreas = undefined
				}
			}
		})

		return matches
	}

	filterBool(area: AREACONFIG) {
		// @ts-ignore
		/*
		if (area.callback && this[area.callback]) {
			// @ts-ignore
			return this.env.checkUserContainsSomeRoles(area.permission!) //&&this[area.callback].call(this, area)
		} else {
			return this.env.checkUserContainsSomeRoles(area.permission!)
		}
		*/
		if (area.permission === undefined) return true
		return this.env.checkUserContainsSomePermissions(area.permission!)
	}

	getAllAreas(): AREACONFIG[] {
		return availableAreas
	}

	getArea(area: string): AREACONFIG {
		// @ts-ignore
		return this.getAvailableAreas()?.find((a) => a.code === area)
	}

	getSubArea(area: string, subarea: string): AREACONFIG {
		// @ts-ignore
		return this.getArea(area)?.subAreas?.find((a) => a.code === subarea)
	}

	/**
	 * Area Callbacks
	 */
	private callback(area: AREACONFIG): boolean {
		// logic
		return true
	}

	finalArea!: AREACONFIG | undefined
	recursiveFindNestedArea(
		areaName: string,
		areas: AREACONFIG[],
	): AREACONFIG | undefined {
		areas.forEach((area) => {
			if (area.name.toLowerCase() === areaName) {
				this.finalArea = area
			}

			if (area.subAreas) {
				this.finalArea = this.recursiveFindNestedArea(
					areaName,
					area.subAreas,
				)
			}
			return undefined
		})
		return this.finalArea
	}
}
