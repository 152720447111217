import {
	UserPermissionConfig,
	UserRoleConfig,
} from '../core/authorization/roles/roles-config'

export enum AREA {
	AUTH = 'areas.auth',
	CUSTOMER = 'areas.customer',
	BACKOFFICE = 'areas.backoffice',
	DRIVER = 'areas.driver',
}

export enum SUBAREA {
	AUTH_LOGIN = 'areas.auth.login',
	AUTH_REGISTER = 'areas.auth.register',
	AUTH_CONFIRM = 'areas.auth.confirm',
	AUTH_RECOVER = 'areas.auth.recover',
	AUTH_TFA = 'areas.auth.tfa',

	BACKOFFICE_REQUESTS = 'areas.backoffice.requests',
	BACKOFFICE_USERS = 'areas.backoffice.users',
	BACKOFFICE_DRIVERS = 'areas.backoffice.drivers',
	BACKOFFICE_VEHICLES = 'areas.backoffice.vehicles',
	BACKOFFICE_STOPS = 'areas.backoffice.stops',
	BACKOFFICE_ZONES = 'areas.backoffice.zones',
	BACKOFFICE_AGGREGATES = 'areas.backoffice.aggregates',
	BACKOFFICE_MONITORING = 'areas.backoffice.monitoring',
	BACKOFFICE_FLEET = 'areas.backoffice.fleet',
	BACKOFFICE_ROUTES = 'areas.backoffice.routes',
	BACKOFFICE_FARE = 'areas.backoffice.fare',
	BACKOFFICE_OPERATORS = 'areas.backoffice.operators',
	BACKOFFICE_PAYMENTS = 'areas.backoffice.payments',
	BACKOFFICE_TRIPS = 'areas.backoffice.trips',
	BACKOFFICE_DRIVER_REQUESTS = 'areas.backoffice.driver-requests',
	BACKOFFICE_DRIVER_TRIPS = 'areas.backoffice.driver-trips',
	BACKOFFICE_TRAVEL = 'areas.backoffice.travel',
}

export interface AREACONFIG {
	code: AREA | SUBAREA
	name: string
	role?: UserRoleConfig[]
	permission?: UserPermissionConfig[]
	route?: string
	routerLinkActiveOptions?: RouterLinkActiveOptions
	callback?: string
	visible?: boolean
	subAreasVisible?: boolean
	subAreas?: AREACONFIG[]
}

export interface RouterLinkActiveOptions {
	paths: 'exact' | 'subset'
	queryParams: 'exact' | 'subset' | 'ignored'
	matrixParams: 'exact' | 'subset' | 'ignored'
	fragment: 'exact' | 'ignored'
}

export const availableAreas: AREACONFIG[] = [
	{
		code: AREA.AUTH,
		name: 'Auth',
		route: '/auth',
		visible: true,
		subAreasVisible: true,
		subAreas: [
			{
				code: SUBAREA.AUTH_LOGIN,
				name: 'Login',
				route: '/auth/login',
				visible: true,
			},
			{
				code: SUBAREA.AUTH_REGISTER,
				name: 'Register',
				route: '/auth/register',
				visible: true,
			},
			{
				code: SUBAREA.AUTH_CONFIRM,
				name: 'Confirm',
				route: '/auth/confirm',
				visible: true,
			},
			{
				code: SUBAREA.AUTH_RECOVER,
				name: 'Recover',
				route: '/auth/recover',
				visible: true,
			},
			{
				code: SUBAREA.AUTH_TFA,
				name: 'TFA',
				route: '/auth/tfa',
				visible: true,
			},
		],
	},
	{
		code: AREA.BACKOFFICE,
		name: 'Backoffice',
		route: '/backoffice',
		visible: true,
		subAreasVisible: true,
		permission: [
			UserPermissionConfig.ReadUser,
			UserPermissionConfig.ReadVehicle,
			UserPermissionConfig.ReadOperator,
			UserPermissionConfig.ReadZone,
			UserPermissionConfig.ReadStop,
			UserPermissionConfig.ReadPopulationAggregate,
			UserPermissionConfig.ReadTravelRequest,
			UserPermissionConfig.ViewTripApprovedTravelRequest,
			UserPermissionConfig.ReadTrip,
		],
		subAreas: [
			{
				code: SUBAREA.BACKOFFICE_TRAVEL,
				name: 'Travel',
				visible: true,
				permission: [
					UserPermissionConfig.ReadTrip,
					UserPermissionConfig.ReadMonitoring,
				],
				subAreas: [
					{
						code: SUBAREA.BACKOFFICE_TRIPS,
						name: 'Trips',
						route: '/backoffice/trips',
						visible: true,
						permission: [
							UserPermissionConfig.ReadTrip,
							UserPermissionConfig.ReadMonitoring,
						],
					},
					{
						code: SUBAREA.BACKOFFICE_REQUESTS,
						name: 'Requests',
						route: '/backoffice/requests',
						visible: true,
						permission: [UserPermissionConfig.ReadMonitoring],
					},
				],
			},

			{
				code: SUBAREA.BACKOFFICE_FLEET,
				name: 'Fleet',
				visible: true,
				permission: [
					UserPermissionConfig.ReadVehicle,
					UserPermissionConfig.ReadOperator,
				],
				subAreas: [
					{
						code: SUBAREA.BACKOFFICE_OPERATORS,
						name: 'Operators',
						route: '/backoffice/operators',
						visible: true,
						permission: [UserPermissionConfig.ReadOperator],
					},
					{
						code: SUBAREA.BACKOFFICE_VEHICLES,
						name: 'Vehicles',
						route: '/backoffice/vehicles',
						visible: true,
						permission: [UserPermissionConfig.ReadVehicle],
					},
				],
			},
			{
				code: SUBAREA.BACKOFFICE_USERS,
				name: 'Users',
				route: '/backoffice/users',
				visible: true,
				permission: [UserPermissionConfig.ReadUser],
			},
			{
				code: SUBAREA.BACKOFFICE_ROUTES,
				name: 'routes',
				visible: true,
				permission: [UserPermissionConfig.ReadPopulationAggregate],
				subAreas: [
					{
						code: SUBAREA.BACKOFFICE_AGGREGATES,
						name: 'Aggregates',
						route: '/backoffice/aggregates',
						visible: true,
						permission: [
							UserPermissionConfig.ReadPopulationAggregate,
						],
					},
					{
						code: SUBAREA.BACKOFFICE_ZONES,
						name: 'Zones',
						route: '/backoffice/zones',
						visible: true,
						permission: [UserPermissionConfig.ReadZone],
					},
					{
						code: SUBAREA.BACKOFFICE_STOPS,
						name: 'Stops',
						route: '/backoffice/stops',
						visible: true,
						permission: [UserPermissionConfig.ReadStop],
					},
				],
			},
			{
				code: SUBAREA.BACKOFFICE_MONITORING,
				name: 'Monitoring',
				route: '/backoffice/monitoring',
				visible: true,
				permission: [UserPermissionConfig.ReadMonitoring],
			},
			{
				code: SUBAREA.BACKOFFICE_PAYMENTS,
				name: 'Payments',
				route: '/backoffice/payments',
				visible: true,
				permission: [UserPermissionConfig.ReadPayment],
			},
			{
				code: SUBAREA.BACKOFFICE_FARE,
				name: 'Fare',
				route: '/backoffice/fare',
				visible: true,
				permission: [UserPermissionConfig.ReadFare],
			},
		],
	},
]
